<template>
  <div class="sss" :style="popStyle" v-show="popShow">
    <img src="../static/off.png" class="popOffImg" @click="offPop" />
    <div class="titleBox">
      <span class="title">{{ peo.tag }}</span>
      <img class="imgright" src="../static/Union.png" alt="" />
    </div>
    <div v-show="isTk" class="qcode" style="">
      <img src="../static/3017186939381258720qcode.png" alt="" />
    </div>
    <div class="content">
      <div class="line" v-for="(item, index) in Object.keys(peo)" :key="index">
        <span class="lable" v-if="item != 'tag'">{{ item }}：</span>
        <span class="em" v-if="item != 'tag'">{{ peo[item] }}</span>
      </div>
    </div>

    <!-- placement="bottom-end" -->
    <!-- <el-popover
      :title="peo.title"
      :width="200"
      trigger="hover"
      content="this is content, this is content, this is content"
      placement="top-start"
      :visible="popShow"
      popper-class="myPopperClass"
    >
      <template #reference>
        <div class="m-2" :style="popStyle"></div>
      </template>
      <span 
        v-for="key,index in Object.keys(peo)"
        :key="index"
        class="popcontent"
      >
        {{ peo[key] }}
      </span>
    </el-popover> -->
  </div>
</template>

<script lang="js">
import { defineComponent,reactive,toRefs } from "vue";
// import { ElPopover } from "element-plus";
// import _axios from "../api/axios.js";



export default defineComponent({
  name: "pop",
  components : { },
  emits:['offPop'],
  props:{popStyle:Object,popShow:Boolean,peo:Object,isTk:Boolean},
  setup(props,{emit}) {
    console.log(props)

    const state = reactive(props)
		const { popStyle,popShow,peo,isTk } = toRefs(state);
    // let popStyle=reactive({top:0,left:0})
    // const popShow=ref(true)
    // const peo=reactive({name:'哈哈哈',"relation":'户主',"address":'江苏省扬州市高邮市三垛镇,兴联村111号'})
    // peo.value.name=peo.value.relation+'：'+peo.value.name
    // debugger
    // onMounted(() => {

    // })
      // eslint-disable-next-line vue/no-dupe-keys
      // const peo=reactive({})
      // peo['消防栓']='4个';
      // peo['防爆球']='2个';
      // peo['防爆叉']='3个';
      // peo['抓捕器']='4个';
        const offPop=() => {
          emit('offPop',{msg:'offPop'})
        }
        // eslint-disable-next-line vue/no-dupe-keys
      return { popStyle,popShow,peo,isTk,offPop };
    },
});
</script>

<style scope>
.m-2 {
  position: fixed;
  top: 100px;
  left: 100px;
  /* display: none !important; */
}
.myPopperClass {
  background-color: blue !important;
  opacity: 0.5;
}
.myPopperClass .el-popover__title {
  font-weight: 600;
  font-size: 18px;
  color: white;
}
.popcontent {
  text-indent: 50px;
  color: white;
}
.sss {
  position: absolute;
  background-image: url(../static/bg.png);
  min-height: 214px;
  min-width: 248px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: translate(-50%, -100%);
  /* transform: translate(-50%, -50%); */
}
.titleBox {
  overflow: hidden;
  pointer-events: none;
}
.title {
  display: block;
  float: left;
  font-family: "江城斜黑体";
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  letter-spacing: 2px;
  background: linear-gradient(
    90deg,
    #ffffff -16.41%,
    rgba(45, 255, 172, 0.69) 124.22%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 18px;
  margin-left: 22px;
}
.imgright {
  float: left;
  width: 21.6px;
  height: 6.03px;
  margin-left: 6px;
  margin-top: 24px;
}
.qcode {
  /* width: 100%; */
  /* height: 100%; */
  float: left;
  width: 150px;
  height: 150px;
  background: #ffffff;
  margin-left: 30px;
  margin-top: 20px;
}
.qcode img {
  display: block;
  width: 100%;
  height: 100%;
}
.content {
  padding: 20px 22px 42px 22px;
  font-family: "Alibaba PuHuiTi 2.0";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* or 117% */
  letter-spacing: 2px;
  float: left;
}
.content .line {
  display: block;
  margin-top: 15px;
  overflow: hidden;
}
.content .line:first-child {
  margin-top: 0px;
}

.content .line .lable {
  /* float: left; */
  color: #ffffff;
}

.content .line .em {
  /* float: left; */
  color: #27ffaa;
}

.popOffImg {
  position: absolute;
  display: block;
  right: 5%;
  top: 20%;
  width: 15px;
  height: 15px;
  padding: 5px;
}
.popOffImg:hover {
  background: rgb(39, 255, 170, 0.7);
  border-radius: 50%;
}
</style>
